import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {environment} from '../../environments/environment';
import {getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {getPerformance, providePerformance} from '@angular/fire/performance';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {USE_DEVICE_LANGUAGE} from '@angular/fire/compat/auth';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './token.interceptor';
import {DialogModule} from '../@shared/components/dialog/dialog.module';
import {AlertModule} from '../@shared/components/alert/alert.module';
import {SocketIoModule} from "ngx-socket-io";
import 'moment/locale/hu';
import {SocketService} from "../@shared/service/socket.service";
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import 'moment/locale/hu';
import HU from '@angular/common/locales/hu';
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {MeilisearchClient} from "./meili-search-client";
import {MatDateFormat} from './mat-date-format';


registerLocaleData(HU);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SocketIoModule,
    MatNativeDateModule,

    DialogModule,
    AlertModule,

    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    providePerformance(() => getPerformance()),
  ],
  providers: [
    MeilisearchClient,
    ScreenTrackingService,
    UserTrackingService,
    SocketService,
    {provide: LOCALE_ID, useValue: 'hu-HU'},
    {provide: USE_DEVICE_LANGUAGE, useValue: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'hu-HU'},
    {provide: DateAdapter, useClass: MatDateFormat},
    {provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true, displayDefaultIndicatorType: false }}
  ],
})
export class ConfigModule {
}
