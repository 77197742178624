import {NativeDateAdapter} from '@angular/material/core';
import {Injectable} from '@angular/core';

@Injectable()
export class MatDateFormat extends NativeDateAdapter {

  getFirstDayOfWeek(): number {
    return 1;
  }

}

// export const MAT_CUSTOM_DATE_FORMAT: MatDateFormats = {
//   parse: {
//     dateInput: 'YYYY.MM.DD',
//   },
//   display: {
//     dateInput: 'YYYY MMM',
//     monthYearLabel: 'YYYY MMM',
//     dateA11yLabel: 'YYYY MMM',
//     monthYearA11yLabel: 'YYYY MMMM',
//   },
// };
