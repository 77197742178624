<mat-toolbar class="bg-white shadow-3 sticky-top">
  <a *ngIf="mobileQuery2.matches else date" (click)="toogleSidenav()" mat-button>
    <mat-icon>menu</mat-icon>
  </a>
  <ng-template #date>
    <div *ngIf="!PROD" class="notification">Teszt környezet</div>
    <time>{{ time | date:'yyyy.MM.dd. - HH:mm:ss' }}</time>
  </ng-template>

  <div class="ms-auto">

    <a *ngIf="currentUser | async as user; else loading;" [matMenuTriggerFor]="menu" mat-button [disabled]="disabled" [ngClass]="{'disabled': disabled}">
      <span *ngIf="disabled" class="spinner-border spinner-border-sm"></span>
      {{ user?.displayName ?? user.email }}
    </a>
    <ng-template #loading>
        <span class="spinner-border spinner-border-sm"></span>
    </ng-template>

    <mat-menu #menu="matMenu">
      <a *ngIf="mobileQuery.matches" mat-menu-item (click)="toggleFullscreen()">
        <mat-icon>{{ isFullScreen() ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon> <span>Teljes képernyő</span>
      </a>
      <a routerLink="/oauth/change-password" class="disabled" mat-menu-item>
        <mat-icon>lock_reset</mat-icon> <span>Jelszó modosítása</span>
      </a>
      <a [disabled]="disabled" [ngClass]="{'disabled': disabled}" mat-menu-item (click)="signout();">
        <mat-icon>logout</mat-icon> <span>Kijelentkezés</span>
      </a>
    </mat-menu>
    <a *ngIf="!mobileQuery.matches" (click)="toggleFullscreen()" mat-icon-button><mat-icon>{{ isFullScreen() ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon></a>

  </div>
</mat-toolbar>
