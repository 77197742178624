import { Injectable } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ComponentType} from "@angular/cdk/overlay";
import {DialogAreYouSureComponent} from "./dialog-are-you-sure/dialog-are-you-sure.component";
import {firstValueFrom} from "rxjs";
import {DialogSuccessComponent} from "./dialog-success/dialog-success.component";

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialog: MatDialog,
    private router: Router,
  ) {
  }

  openSuccessDialog(title: string, message: string, sec: number, navigate?: any, navigationTimeOut?: boolean) {
    this.dialog.open(DialogSuccessComponent, {data: {title, message, sec}, backdropClass: '', disableClose: true});
    if (navigate) {
      const t1 = setTimeout(() => {
        this.router.navigate(navigate).then();
        clearTimeout(t1);
      }, navigationTimeOut ? (sec * 1000) : 0);
    }
  }

  openAreYouSoreDialog(data?: {title?: string; message?: string}): Promise<any> {
    const dialogRef = this.dialog.open(DialogAreYouSureComponent, {data: data ?? {}, backdropClass: '', disableClose: true, maxWidth: 600});
    return firstValueFrom(dialogRef.afterClosed());
  }

  open(component: ComponentType<any>, config?: MatDialogConfig) {
    this.dialog.open(component, config);
  }

  closeAllDialog() {
    this.dialog.closeAll();
  }

}
