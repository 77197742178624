import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {AlertComponent} from './alert.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private snackBar: MatSnackBar,
  ) {
  }

  info(title?: string, message?: string, config?: MatSnackBarConfig): void {
    const basicClass: string[] = ['bg-info', 'mat-alert'];
    const duration = config?.duration || 5000;
    this.snackBar.openFromComponent(AlertComponent, {
      data: {title: title || 'Sikeres művelet!', message: message || 'A kiválasztott művelet hibamentesen vévigment rendszerünkben!', duration},
      ...config,
      duration,
      panelClass: config?.panelClass ? [...config.panelClass, ...basicClass] : [...basicClass],
    });
  }

  success(title?: string, message?: string, config?: MatSnackBarConfig): void {
    const basicClass: string[] = ['bg-success', 'mat-alert'];
    const duration = config?.duration || 5000;
    this.snackBar.openFromComponent(AlertComponent, {
      data: {title: title || 'Sikeres művelet!', message: message || 'A kiválasztott művelet hibamentesen vévigment rendszerünkben!', duration},
      ...config,
      duration,
      panelClass: config?.panelClass ? [...config.panelClass, ...basicClass] : [...basicClass],
    });
  }

  warning(title?: string, message?: string, config?: MatSnackBarConfig): void {
    const basicClass: string[] = ['bg-warning', 'mat-alert'];
    const duration = config?.duration || 5000;
    this.snackBar.openFromComponent(AlertComponent, {
      data: {title: title || 'Figyelmeztetés!', message: message || 'Ez egy alapértelmzett üzenet! Kérjük a rendszer használata során legyen nagyon körültekintő!', duration},
      ...config,
      duration,
      panelClass: config?.panelClass ? [...config.panelClass, ...basicClass] : [...basicClass],
    });
  }

  error(title?: string | false, message?: string, config?: MatSnackBarConfig): void {
    const basicClass: string[] = ['bg-danger', 'mat-alert'];
    const duration = config?.duration || 5000;
    this.snackBar.openFromComponent(AlertComponent, {
      data: {title: title || 'Sikertelen művelet!', message: message || 'A kiválasztott művelet során valamilyen hiba került a rendszerbe! Kérjük kis idő elteltével próbálja meg újra. Ha nem oldódik meg, kérjük keresse ügyfélszolgálatunkat.', duration},
      ...config,
      duration,
      panelClass: config?.panelClass ? [...config.panelClass, ...basicClass] : [...basicClass],
    });
  }


}
