<div class="head">
  <div class="box shadow-1 d-flex align-items-center justify-content-center">
    <img class="img-fluid" src="https://www.basecloud.hu/cdn/logos/horizontal.png" alt="">
  </div>

  <div class="owner">
    <img class="img-fluid" src="assets/rsr-icon.png" alt="">
  </div>
</div>

<div class="links">
  <mat-nav-list>
    <a mat-list-item [routerLink]="['']" [routerLinkActive]="['active-list-item']" [routerLinkActiveOptions]="{exact: true}" (click)="closeSidenav()">
      <i class="fa-regular fa-house-laptop"></i> ADMIN Főoldal
    </a>


    <a *ngIf="role === 'administrator'" mat-list-item [routerLink]="['news']" [routerLinkActive]="['active-list-item']" (click)="closeSidenav()">
      <i class="fa-regular fa-newspaper"></i> Hírek
    </a>
    <a *ngIf="role === 'administrator'" mat-list-item [routerLink]="['events']" [routerLinkActive]="['active-list-item']" (click)="closeSidenav()">
      <i class="fa-sharp fa-regular fa-volleyball"></i> Események
    </a>
    <a *ngIf="role === 'administrator'" mat-list-item [routerLink]="['summer-camp']" [routerLinkActive]="['active-list-item']" (click)="closeSidenav()">
      <i class="fa-sharp fa-regular fa-umbrella-beach"></i> Nyári tábor
    </a>

    <hr *ngIf="role === 'administrator'" style="border-bottom: 1px solid rgba(186, 186, 186, 0.75)">

    <a *ngIf="role === 'administrator'" mat-list-item [routerLink]="['partners']" [routerLinkActive]="['active-list-item']" (click)="closeSidenav()">
      <i class="fa-regular fa-handshake"></i> Partnertörzs
    </a>
    <a mat-list-item [routerLink]="['reservations']" [routerLinkActive]="['active-list-item']" (click)="closeSidenav()">
      <i class="fa-regular fa-computer-mouse-scrollwheel"></i> Foglalások
    </a>
    <a mat-list-item [routerLink]="['calendars']" [routerLinkActive]="['active-list-item']" (click)="closeSidenav()">
      <i class="fa-regular fa-calendar-lines"></i> Naptárak
    </a>
    <a *ngIf="role === 'administrator'" mat-list-item [routerLink]="['completion-certificate']" [routerLinkActive]="['active-list-item']" (click)="closeSidenav()">
      <i class="fa-regular fa-file-excel"></i> Teljesítés igazolás
    </a>

    <hr *ngIf="role === 'administrator'" style="border-bottom: 1px solid rgba(186, 186, 186, 0.75)">

    <a *ngIf="role === 'administrator'" mat-list-item [routerLink]="['users']" [routerLinkActive]="['active-list-item']" (click)="closeSidenav()">
      <i class="fa-regular fa-users"></i> Felhasználók
    </a>
    <a *ngIf="role === 'administrator'" mat-list-item [routerLink]="['support']" [routerLinkActive]="['active-list-item']" (click)="closeSidenav()">
      <i class="fa-regular fa-circle-question"></i> Segítségkérés
    </a>

  </mat-nav-list>
</div>
