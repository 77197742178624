import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, Optional, Output} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import screenfull from 'screenfull';
import {Auth, authState, User} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {map, Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';


@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss']
})
export class NavHeaderComponent implements OnDestroy {
  @Output() toogleMainSidenav = new EventEmitter<void>();

  public mobileQuery: MediaQueryList;
  public mobileQuery2: MediaQueryList;

  public time: Date;
  private readonly mobileQueryListener: () => void;
  private readonly mobileQueryListener2: () => void;

  public PROD = environment.production;
  public disabled = false;
  public currentUser: Observable<User>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    @Optional() private auth: Auth,
    @Optional() private router: Router,
  ) {
    this.getTime();
    this.currentUser = authState(this.auth);

    this.mobileQuery = media.matchMedia('(max-width: 450px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('cahnge', this.mobileQueryListener);
    this.mobileQuery2 = media.matchMedia('(max-width: 850px)');
    this.mobileQueryListener2 = () => changeDetectorRef.detectChanges();
    this.mobileQuery2.addEventListener('change', this.mobileQueryListener2);
  }

  ngOnDestroy() {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
    this.mobileQuery2.removeEventListener('change', this.mobileQueryListener2);
  }


  public getTime() {
    this.time = new Date();
    setInterval(() => {
      this.time = new Date();
    }, 100);
  }

  toogleSidenav() {
    this.toogleMainSidenav.emit();
  }

  toggleFullscreen() {
    if (screenfull.isEnabled) screenfull.toggle();
    else screenfull.request();
  }
  isFullScreen(): boolean {
    return screenfull.isFullscreen;
  }

  signout() {
    this.disabled = true;
    this.auth.signOut()
      .then(() => {
        this.disabled = false;
        window.location.href = `${this.router.url.split('/')[0]}/`;
      })
      .catch(e => {
        this.disabled = false;
      })
  }


}
