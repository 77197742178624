import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertService} from './alert.service';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import { AlertComponent } from './alert.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
  declarations: [
    AlertComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatIconModule,
  ],
  providers: [
    AlertService,
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {horizontalPosition: 'center', verticalPosition: 'top'}},
  ]
})
export class AlertModule {
}
