import {Injectable} from "@angular/core";
import {Socket} from "ngx-socket-io";
import {AlertService} from "../components/alert/alert.service";
import {Auth} from "@angular/fire/auth";
import {BehaviorSubject, Observable, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SocketService extends Socket {
  private $updates: Subscription;
  private $exception: Subscription;

  private item: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private alert: AlertService,
    private auth: Auth,
  ) {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;

    super({ url: `${protocol}//${hostname === 'localhost' ? 'localhost:3000' : hostname}`, options: {path: '/api/websocket', transports: ['websocket']} });
    this.autehnticate();

    super.on('connect_error', (e: any) => {
      this.alert.error(e.message);
      this.autehnticate();
    });

    this.exception();
  }

  subscribe(eventName: string, args?: {[type: string]: string}) {
    this.exception();
    super.emit(`${eventName}/subscribe`, args, (socketInitData: any) => {
      this.handleDate(socketInitData);
      this.subscribeUpdates(eventName);
    });
  }
  unsubscribe(eventName: string, args?: {[type: string]: string}) {
    this.item = new BehaviorSubject<any>(null);
    super.emit(`${eventName}/unsubscribe`, args);
    if (this.$updates) {
      this.$updates.unsubscribe();
      this.$updates = null;
    }
    if (this.$exception) {
      this.$exception.unsubscribe();
      this.$exception = null;
    }
  }
  exception() {
    this.$exception = super.fromEvent('exception')
      .subscribe(socket => this.handleDate(socket));
  }


  getSocketData<T>(): Observable<T | any> {
    return this.item.asObservable();
  }
  getSocketDataOnce<T>(): T {
    return this.item.getValue();
  }


  private autehnticate() {
    this.auth.currentUser.getIdToken().then(token => {
      this.ioSocket.auth = {token: token};
      super.connect();
    });
  }
  private handleDate(data: any | {error: boolean, code: string, status: string | number, title?: string, message: string}) {
    if (data?.error) {
      this.item.error(data);
      this.alert.error(data?.title ? `${data.status} - ${data.title}` : `${data.status} - ${data.code}`, data.message);
    }
    else this.item.next(data);
  }
  private subscribeUpdates(eventName: string) {
    this.$updates = super.fromEvent<any>(`${eventName}/change`)
      .subscribe(socket => this.handleDate(socket));
  }


}
