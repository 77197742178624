import {Injectable} from '@angular/core';
import {InstantMeiliSearchInstance} from "@meilisearch/instant-meilisearch/src/types";
import {instantMeiliSearch} from "@meilisearch/instant-meilisearch";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MeilisearchClient {
  public searchClient: InstantMeiliSearchInstance;

  constructor(
    private http: HttpClient,
  ) {
    const httpClient = async (url: string, opts?: any) => {
      const headers = new HttpHeaders(opts?.headers);
      const method = (opts?.method?.toLocaleUpperCase() as any) ?? 'GET';
      const body = opts?.body;
      const params = new HttpParams({fromObject: opts?.params});

      return await firstValueFrom(this.http.post(`/api/search/multi`, body, {headers, params}));
    }
    this.searchClient = instantMeiliSearch('http://localhost', '', {httpClient}).searchClient;
  }

}
