import packageJSON from '../../package.json';

export const environment = {
  production: false,
  ...packageJSON,
  firebase: {
    apiKey: "AIzaSyD8AiG4ZhxQh39qWxvjvxtI6D7Br_PNDZM",
    authDomain: "rsr-dev-hu.firebaseapp.com",
    projectId: "rsr-dev-hu",
    storageBucket: "rsr-dev-hu.appspot.com",
    messagingSenderId: "887505415632",
    appId: "1:887505415632:web:5f8b15d8e7d750058a1dd5"
  },
};
