import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {switchMap} from 'rxjs/operators';
import {from, Observable} from 'rxjs';
import {Auth} from '@angular/fire/auth';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private inj: Injector,
    private auth: Auth,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('oauthCallback') > -1) return next.handle(request);

    return from(this.auth.currentUser.getIdTokenResult()).pipe(
      switchMap(token => {
        request = request.clone({
          setHeaders: {Authorization: `Bearer ${token.token}`}
        });
        return next.handle(request);
      }),
    );
  }
}
