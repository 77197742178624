import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard, customClaims, redirectLoggedInTo, redirectUnauthorizedTo} from "@angular/fire/auth-guard";
import {RootComponent} from "./root.component";
import {map, pipe} from "rxjs";

const redirectUnauthorized = () => redirectUnauthorizedTo(['oauth/signin']);
const redirectLoggedIn = () => redirectLoggedInTo(['/']);
const adminOnly = () => pipe(customClaims, map(claims => claims.role === 'administrator'));

const routes: Routes = [
  {path: 'oauth', children: [
    {path: 'signin', canActivate: [AuthGuard], data: {authGuardPipe: redirectLoggedIn}, loadChildren: () => import('./pages/_oauth/oauth-signin-page/signin.module').then(m => m.SigninModule)},
    {path: 'action', canActivate: [AuthGuard], data: {authGuardPipe: redirectLoggedIn}, loadChildren: () => import('./pages/_oauth/oauth-action-page/action.module').then(m => m.ActionModule)},
    {path: 'forgot-password', canActivate: [AuthGuard], data: {authGuardPipe: redirectLoggedIn}, loadChildren: () => import('./pages/_oauth/oauth-forgot-password-page/forgot-password.module').then(m => m.ForgotPasswordModule)},
    {path: 'reset-password', canActivate: [AuthGuard], data: {authGuardPipe: redirectLoggedIn}, loadChildren: () => import('./pages/_oauth/oauth-reset-password-page/reset-password.module').then(m => m.ResetPasswordModule)},
    {path: 'change-password', canActivate: [AuthGuard], data: {authGuardPipe: redirectUnauthorized}, loadChildren: () => import('./pages/_oauth/oauth-change-password-page/change-password.module').then(m => m.ChangePasswordModule)},
  ]},
  {path: '', canActivate: [AuthGuard], data: {authGuardPipe: redirectUnauthorized}, component: RootComponent, children: [
    {path: '', loadChildren: () => import('./pages/home-page/home.module').then(m => m.HomeModule)},
    {path: 'users', canActivate: [AuthGuard], data: {authGuardPipe: adminOnly}, loadChildren: () => import('./pages/users-page/users.module').then(m => m.UsersModule)},
    {path: 'partners', canActivate: [AuthGuard], data: {authGuardPipe: adminOnly}, loadChildren: () => import('./pages/partners-page/partners.module').then(m => m.PartnersModule)},
    {path: 'news', canActivate: [AuthGuard], data: {authGuardPipe: adminOnly}, loadChildren: () => import('./pages/news-page/news.module').then(m => m.NewsModule)},
    {path: 'events', canActivate: [AuthGuard], data: {authGuardPipe: adminOnly}, loadChildren: () => import('./pages/events-page/events.module').then(m => m.EventsModule)},
    {path: 'summer-camp', canActivate: [AuthGuard], data: {authGuardPipe: adminOnly}, loadChildren: () => import('./pages/summer-camp-page/summer-camp.module').then(m => m.SummerCampModule)},
    {path: 'reservations', loadChildren: () => import('./pages/reservations-page/reservations.module').then(m => m.ReservationsModule)},
    {path: 'calendars', loadChildren: () => import('./pages/calendar-page/calendar.module').then(m => m.CalendarModule)},
    {path: 'completion-certificate', canActivate: [AuthGuard], data: {authGuardPipe: adminOnly}, loadChildren: () => import('./pages/completion-certificate-page/completion-certificate.module').then(m => m.CompletionCertificateModule)},
    // {path: '', loadChildren: () => import('./pages/').then(m => m)},
    // {path: '', loadChildren: () => import('./pages/').then(m => m)},

    {path: '**', loadChildren: () => import('./pages/_error-page/error.module').then(m => m.ErrorModule)},
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
