<div [class.example-is-mobile]="mobileQuery.matches" class="example-container">

  <mat-sidenav-container class="admin-sidenav-container">
    <mat-sidenav #sidenav [fixedInViewport]="mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="!mobileQuery.matches" style="width: 250px">
      <app-nav-side (closeMainSidenav)="mobileQuery.matches ? sidenav.close() : null"></app-nav-side>
      <div style="height: 24px"></div>
      <div class="inner-content">
        <ng-container *ngIf="!PROD">DEV - </ng-container>v{{ VERSION }}
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <app-nav-header (toogleMainSidenav)="sidenav.toggle()"></app-nav-header>
      <div class="m-3 m-md-4">
        <ng-content></ng-content>
      </div>
      <div class="container">
        <div class="row my-3">
          <div class="col-12 text-center text-muted">
            <p style="font-size: .8rem; margin-bottom: 0">Üzemeltető: Riz Levente Sport- és Rendezvényközpont - www.rsr.hu</p>
            <p style="font-size: .8rem; margin-bottom: 0">A rendszert tervezte és fejlesztette: Infohullám Kft. - www.infohullam.hu</p>
            <p style="font-size: .8rem">Minden jog fenntartva / 2021 - {{ YEAR }}</p>
          </div>
        </div>
      </div>

    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
