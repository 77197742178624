import {Component} from "@angular/core";
import {NavModule} from "./@shared/components/nav/nav.module";
import {RouterOutlet} from "@angular/router";

@Component({
  standalone: true,
  selector: `app-root`,
  imports: [
    NavModule,
    RouterOutlet
  ],
  template: `
    <app-nav>
      <router-outlet></router-outlet>
    </app-nav>
  `
})
export class RootComponent {
}
