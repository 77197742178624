import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogSuccessComponent} from './dialog-success/dialog-success.component';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {DialogService} from './dialog.service';
import {DialogSureComponent} from './dialog-sure/dialog-sure.component';
import { DialogAreYouSureComponent } from './dialog-are-you-sure/dialog-are-you-sure.component';


@NgModule({
  declarations: [
    DialogSuccessComponent,
    DialogSureComponent,
    DialogAreYouSureComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
  ],
  providers: [
    DialogService,
    {provide: MatDialogRef, useValue: {}},
  ]
})
export class DialogModule {
}
