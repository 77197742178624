import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavComponent} from './nav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {NavSideComponent} from './nav-side/nav-side.component';
import {NavHeaderComponent} from './nav-header/nav-header.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    NavComponent,
    NavSideComponent,
    NavHeaderComponent
  ],
  exports: [
    NavComponent
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    MatListModule,
    RouterModule
  ]
})
export class NavModule {
}
