import {Component, EventEmitter, Output} from '@angular/core';
import {Auth} from "@angular/fire/auth";

@Component({
  selector: 'app-nav-side',
  templateUrl: './nav-side.component.html',
  styleUrls: ['./nav-side.component.scss']
})
export class NavSideComponent {
  @Output() closeMainSidenav = new EventEmitter<void>();

  public role: 'security' | 'administrator';

  constructor(
    private auth: Auth,
  ) {
    this.auth.currentUser.getIdTokenResult()
      .then(token => this.role = token.claims.role);
  }

  closeSidenav() {
    this.closeMainSidenav.emit();
  }
}
