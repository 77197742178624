import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-success',
  templateUrl: './dialog-success.component.html',
  styleUrls: ['./dialog-success.component.scss']
})
export class DialogSuccessComponent {
  public loadComplete = false;

  constructor(
    public dialogRef: MatDialogRef<DialogSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    const closeAfter = data.sec * 1000;
    const t1 = setTimeout(() => {
      this.loadComplete = true;
      clearTimeout(t1);
    }, 750);
    const t2 = setTimeout(() => {
      this.dialogRef.close();
      clearTimeout(t2);
    }, closeAfter);
  }

}
